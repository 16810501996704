import React from 'react';
import NumberDetails from "../components/NumberDetails/NumberDetails";
import UrlTooltip from "../components/UrlTooltip";
import { Link } from 'react-router-dom';
import s from './Holder.module.scss'

const LeadItem = React.memo(({ lead, index, handleDelete, sendLeadNow }) => {
    const [isSending, setIsSending] = React.useState(false);

    const handleSendNow = async () => {
        setIsSending(true);
        await sendLeadNow(lead.id);
        setIsSending(false);
    };


    const detailsHandler = (partnerName) => {
        const partners = {
            'axela': 'message-warning',
            'cpabandtracker': 'message-info',
            'aivix': 'message-danger',
            'cryp.im': 'message-attantion',
            'trump': 'message-dark'
        };
        return partners[partnerName] || '';
    };

    return (
        <div style={{ "gridTemplateColumns": `25px 40px 90px repeat(4,minmax(100px, 1fr)) 200px 100px 90px 120px`, padding: '8px 0' }}>
            <div onDoubleClick={() => handleDelete(lead.id)} style={{ color: 'red', cursor: 'pointer' }}>✕</div>
            <div>{index + 1}</div>
            <div>{new Date(lead['createdAt']).toLocaleString('uk-UA')}</div>
            <div>{lead['first_name']}</div>
            <div>{lead['last_name']}</div>
            <div>{lead['email']}</div>
            <div><NumberDetails phoneNumber={lead['phone']} /></div>
            <div style={{
                maxHeight: 25,
                overflow: 'hidden',
                position: 'relative',
            }}>
                <UrlTooltip url={lead['ref']} />
            </div>
            <div>
                {lead['utm'] && <div style={{ color: '#337ab7', fontSize: '12px' }}>{lead['utm']}</div>}
                <Link to={`/offers/${lead['Link'].OfferId}/add?search=${lead['Link'].name}`} >{lead['Link'].name}</Link>
                {lead['campaign'] && <div style={{ color: '#b73333', fontSize: '12px' }}>{lead['campaign']}</div>}
            </div>
            <div className={`message ${detailsHandler(lead?.Link?.Offer?.Partner.name || '')}`} style={{ margin: 0, overflow: 'hidden' }} title={`${lead?.Link?.Offer?.Partner.name ?? ''}`}>{lead?.Link?.Offer?.Partner.name ?? ''}</div>
            <div>
                <button onClick={handleSendNow} className="btn btn-success" disabled={isSending}>
                    {isSending ? 'Sending...' : 'Send Now'}
                </button>
            </div>
            <div className={s.transferWrap}>
                {lead?.Link?.Holders.map(el => (
                    <div className={`message message-dark`} style={{ marginBottom: '2px' }}>
                        <span>{el.holder_from} - {el.holder_to}</span>
                    </div>
                ))}
            </div>
            <div className={s.transferWrap}>
                {lead?.Link?.Transfers.map(el => (
                    <div className={`message ${detailsHandler(el.transfer_road)}`}>
                        <span>{el.transfer_from} - {el.transfer_to}</span>
                        <span> → {el.transfer_road}</span>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default LeadItem;
