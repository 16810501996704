import React from "react";

const SimpleModal = ({ children, modalName, closeSetter }) => {

    return <div className={'modal'}>
        <div className={'simpleModalContent'}>
            <button className={`btn btnDanger btnClose`} onClick={e => closeSetter(prevState => ({
                ...prevState,
                [modalName]: false
            }))}>x</button>
            {children}
        </div>
    </div>
}

export default SimpleModal