import React from 'react'
import useAuth from '../hooks/useAuth.hook'
import HomePage from '../ui/HomePage';
import Offers from '../ui/Offers/Offers';
import AddOffersLink from '../ui/Offers/AddOffersLink/AddOffersLink';
import Leads from '../ui/Leads/Leads';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import PrivatRoute from './PrivatRout';
import Login from '../ui/Login/Login';
import Tutorial from '../ui/Tutorial/Tutorial';
import AutologinTutorial from '../ui/Tutorial/AutologinTutorial';
import TrakerAndUtm from '../ui/Tutorial/TrakerAndUtm';
import FormTutorial from '../ui/Tutorial/FormTutorial';
import InputsTutorial from '../ui/Tutorial/InputsTutorial';
import AffiliateIntegration from '../ui/Tutorial/AffiliateIntegration';
import PartnerIntegration from '../ui/Tutorial/PartnerIntegration';

import LeadRedirect from '../ui/LeadRedirect/LeadRedirect';
import Header from '../ui/components/Header';
import Users from '../ui/Users/Users';
import User from '../ui/Users/User';
import Tracker from '../ui/Tracker/Tracker';
import DomainsToTilda from '../ui/Tutorial/DomainToTilda';
import Domains from '../ui/Domains/Domains';
import Holder from '../ui/Holder/Holder';
import Resend from '../ui/Resend/Resend';


const AppRoutes = (props) => {
  const auth = useAuth()
  const params = useParams()

  if (!!params.m)
    <Navigate to={'/conversion'} />

  return auth.isLoaded ? <>
    {!!auth.token ? <Header /> : null}

    <Routes>
      <Route path="/conversion" element={<LeadRedirect />} />

      <Route path='/login' exact element={<Login />} />

      {!!auth.user?.isAdmin ? <>
        <Route
          path="/tutorial"
          element={<PrivatRoute user={auth}>
            <Tutorial />
          </PrivatRoute>
          }>
          <Route index element={<FormTutorial />} />
          <Route path={`partner-integration`} element={<PartnerIntegration />} />
          <Route path={`affiliate-integration`} element={<AffiliateIntegration />} />
          <Route path={`autologin`} element={<AutologinTutorial />} />
          <Route path={`traker-utm-fb`} element={<TrakerAndUtm />} />
          <Route path={`form`} element={<FormTutorial />} />
          <Route path={`inputs`} element={<InputsTutorial />} />
          <Route path={`domains-to-tilda`} element={<DomainsToTilda />} />
        </Route>

        <Route
          path="/"
          element={
            <PrivatRoute user={auth}>
              <HomePage />
            </PrivatRoute>
          }
        />

        <Route
          path="/domains"
          element={
            <PrivatRoute user={auth}>
              <Domains />
            </PrivatRoute>
          }
        />

        <Route
          path="/users"
          element={
            <PrivatRoute user={auth}>
              <Users />
            </PrivatRoute>
          }
        >
          <Route index element={<User />} />
          <Route path={`:id`} element={<User />} />
          <Route path={`create`} element={<User />} />

        </Route>

        <Route
          path="/offers/:id"
          element={
            <PrivatRoute user={auth}>
              <Offers />
            </PrivatRoute>
          }
        />
        <Route
          path="/offers/:id/add"
          element={
            <PrivatRoute user={auth}>
              <AddOffersLink />
            </PrivatRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <PrivatRoute user={auth}>
              <Leads />
            </PrivatRoute>
          }
        />

        <Route
          path="/holder"
          element={
            <PrivatRoute user={auth}>
              <Holder />
            </PrivatRoute>
          }
        />

        <Route
          path="/resend"
          element={
            <PrivatRoute user={auth}>
              <Resend />
            </PrivatRoute>
          }
        />

        <Route
          path="/trackers"
          element={
            <PrivatRoute user={auth}>
              <Tracker />
            </PrivatRoute>
          }
        />
      </> : <>

        <Route
          path="/holder"
          element={
            <PrivatRoute user={auth}>
              <Holder />
            </PrivatRoute>
          }
        />

        <Route
          index
          element={
            <PrivatRoute user={auth}>
              <Leads />
            </PrivatRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <PrivatRoute user={auth}>
              <Leads />
            </PrivatRoute>
          }
        />

        <Route
          path="/trackers"
          element={
            <PrivatRoute user={auth}>
              <Tracker />
            </PrivatRoute>
          }
        />



      </>}
      <Route path='*' element={<Navigate to="/login" />} />
    </Routes>

  </> : <>No Login</>

}

export default AppRoutes