import React from 'react'
import s from './tutorial.module.scss'
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { javascript } from '@codemirror/lang-javascript';

const AffiliateIntegration = () => {
    return <div className='card'>
        <div className={s.main}>Интеграция лендинга с СРМ:
            <div>1. Файл с готовой интеграцией: <a download href='/action.php'>action.php</a></div>
            <div>2. Файл отправляет данные в СРМ. Пример формы с подключенным action.php</div>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', fontSize: '1.4em' }}
                value={`<form action="action.php" method="post">
    <input type="text" name="first_name" placeholder="Имя" required>
    <input type="text" name="last_name" placeholder="Фамилия" required>
    <input type="email" name="email" placeholder="Email" required>
    <input type="tel" name="phone" placeholder="Телефон" required>
    <button type="submit">Отправить</button>    
</form>`}
                extensions={[javascript({ jsx: true })]}
            />
            <div>
                3. Для интеграции в action.php в месте <b>ПОЛУЧИТЕ ЛИНК У АДМИНИСТРАТОРА</b> нужно вставить линк из СРМ для интеграции
            </div>
            <div>4. СРМ принимает поля с такими именами:</div>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', fontSize: '1.4em' }}
                value={`"first_name" - string,
"last_name" - string,
"email" - string,
"phone" - string (Phone Format (E.164)with plus sign), 
"ip" - string (IP Format) подставляется автоматически,
"ref" - ссылка на домен, с которого пришел лид, подставляется автоматически,
`}
                extensions={[javascript({ jsx: true })]}
            />

            <div>5. СРМ возвращает ответ в формате JSON в случаи успеха:</div>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', fontSize: '1.4em' }}
                value={`{
    "statusCode": 200,
    "success": true,
    "id": LEAD_ID,
    "autologin": AUTOLOGIN_URL, - может отсутсвовать
}`}
                extensions={[javascript({ jsx: true })]}
            />
            <div>6. СРМ возвращает ответ в формате JSON в случаи ошибки:</div>
               <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', fontSize: '1.4em' }}
                value={`{
    "statusCode": 500,
    "success": false
}`}
                extensions={[javascript({ jsx: true })]}
            />

            <div>7. action.php настроен на редирект по автологину и на tu.php (можно изменить самостоятельно) в случаи ошибки.</div>
        </div>
    </div>
}

export default AffiliateIntegration