import React from 'react'
import useAuth from '../../hooks/useAuth.hook'
import {Navigate, Route, useNavigate} from 'react-router-dom'

const PrivatRoute = ({children}) => {
    let auth = useAuth()
    if (!!!auth.token) {
        return <Navigate to="/login" replace />;
      }
    
      return children;
}

export default PrivatRoute