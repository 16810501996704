import React from 'react'
import Cookies from 'js-cookie'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import config from '../../config.json'

const AuthProvider = (props) => {
    const {request} = useHttp()
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [user, setUser] = React.useState(null)
    const [token, setTokenData] = React.useState(null)


    const setToken = React.useCallback((tokenData) => {
        setTokenData(tokenData)

        if(tokenData){
            Cookies.set('auth-token', tokenData)
        }else{
            Cookies.remove('auth-token')
        }
    }, [])

    const logOut = React.useCallback((tokenData) => {
        setToken(null)
        setUser(null)
    }, [setToken])

    const loadData = React.useCallback(async () => {
        const tokenData = Cookies.get('auth-token')
        setTokenData(tokenData)
        try {
            if(tokenData){
                const data = await request(`/api/auth/me`, 'POST', {token: tokenData}, {
                    ['x-access-token']: tokenData
                })
                setUser(data)
            }
        } catch (error) {
            setToken(null)
        } finally {
            setIsLoaded(true)
            
        }
    }, [setToken])


    React.useEffect(() => {
        loadData()
    }, [loadData])

    const contextValue = React.useMemo(() => ({
        isLoaded,
        user,
        token,
        setUser,
        setToken,
        logOut
    }), [isLoaded, user, token, setToken, logOut])

  return (
    <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider