import React from 'react';
import { useLocation } from 'react-router-dom';
import './App.scss';
// import Header from './ui/components/Header';
import AppRoutes from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const App = (props) => {
  const location = useLocation();
  return (
    <div className="App">
      <ToastContainer />
      <AppRoutes />
    </div>
  );
}

export default App;
