import React from 'react'
import s from './tutorial.module.scss'

const PartnerIntegration = () => {
    return <div className='card'>
        <div className={s.main}>Интеграция партнерок в СРМ:
            <div>1. Первым делом мы должны получить документацию от партнерки. В файле описана интеграция на примере этой партнерки: <a href="https://afftek.club/partners/manual">afftek</a></div>
            <div>2. Ознакамливаемся с докой, смотрим какие поля нам нужно обязательно передавать и на какой урл</div>
            <div>
                3. После того как немного ознакомились переходим к созданию файла интеграции.,<br />
                Придумываем имя интеграции, например <b>afftek</b> и создаем файл <b>afftek.js</b>
            </div>
            <div>4. Копируем код готовой интеграции по ссылке ниже <a download href='/integration.txt'>Integration</a></div>
            <div>5. Вставляем код в файл afftek.js и начинаем вносить правки в соотвествии с документацией нашего парнера</div>
            <div>6. После того как все поля заполнены, сохраняем файл и по FTP кладем в папку /var/app/server/brokers/</div>
            <div>В нашей СРМ переходим на главную и жмем <b>Add Partner</b></div>
            <img src={require('../../images/ip/2.png')} />
            <div>7. Заполняем поле <b>Name</b> - произвольное имя для партнерки<br />
                Заполняем поле <b>Endpoint</b> - это поле заполняем так как мы назвали файл для интеграции без .js в конце</div>
            <img src={require('../../images/ip/3.png')} />
            <div>8. Интегарция готова, добавляем через СРМку к ней офер и при необходимости интеграционный линк</div>
            <img src={require('../../images/ip/4.png')} />
            <div>9. Заполняем поле <b>Name</b> - произвольное имя для офера (по этому имени будет происходить комуникация между другими интеграциями во время перенаправлени трафика)<br />
                Заполняем поле <b>Flow Hash</b> - уникальный идентификатор офера</div>
            <img src={require('../../images/ip/5.png')} />
            <div>10. Жмем <b>Add</b> и Готов. Офер к партнерке добавлен </div>
        </div>
    </div>
}

export default PartnerIntegration