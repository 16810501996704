import React from 'react'
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash'
import { Chip } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const User = () => {
    const [selectedOffers, setSelectedOffers] = React.useState([]);
    const { offers, getUsersList, setUserhandle } = useOutletContext()


    const [user, setUser] = React.useState({
        "email": "",
        "password": "",
        "Offers": []
    })

    const handleChangeM = (event) => {
        const { value } = event.target;
        setSelectedOffers(value);
        const newOffers = _.filter(offers, el => value.includes(el.id))
        setUser({ ...user, ['Offers']: newOffers });
    };

    const path = useLocation()
    const navigate = useNavigate()

    const handleChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const { request, loading } = useHttp()
    const params = useParams()

    const getUser = async () => {
        const res = await request(`/api/main/user`, "POST", { id: params.id })
        if (!res.user) return false
        setUser(res.user)
        setSelectedOffers(res.user.Offers.map(el => el.id))
    }

    const submitEditHandler = async (event) => {
        event.preventDefault()

        const res = await request(`/api/main/user/edit`, "POST", user)

        getUsersList()
    }

    const submitAddHandler = async (event) => {
        event.preventDefault()

        const res = await request(`/api/main/user/add`, "POST", user)

        setUserhandle(prev => {
            return [...prev, res]
        })
    }

    const submitRemoveHandler = async (event) => {
        event.preventDefault()

        const res = await request(`/api/main/user/delete`, "POST", { id: params.id })

        setUserhandle(prev => {
            _.remove(prev, user => user.id == params.id);
            return [...prev]
        })
        navigate('/users/create')
        // setUserhandle(prev => {
        //     return [...prev, res]
        // })
    }

    React.useEffect(() => {
        getUser()

        return () => {
            setUser({
                "email": "",
                "password": "",
                "Offers": []
            })
        }
    }, [params])
    return (
        <div>
            {user && offers && <Box sx={{ minWidth: 120, maxWidth: '50vw' }}>
                <form onSubmit={path.pathname === `/users/${params.id}` ? submitEditHandler : submitAddHandler}>
                    <h2>{user.id ? `User ID  ${user.id}` : `Create New User`}</h2>
                    <FormControl margin="normal" fullWidth>
                        <TextField label="Login name" variant="outlined" name='email' value={user.email} onChange={handleChange} />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <TextField label="Password" variant="outlined" name='password' value={user.password} onChange={handleChange} />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="demo-multiple-checkbox-label">Offers</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            name={'Offers'}
                            value={selectedOffers}
                            onChange={handleChangeM}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((roleId) => (
                                        <Chip key={roleId} label={offers?.find(e => e.id === roleId)?.name} />
                                    ))}
                                </Box>
                            )} MenuProps={MenuProps}
                        >
                            {offers.map((offer) => (
                                < MenuItem key={offer.id} value={offer.id} >
                                    <Checkbox checked={selectedOffers.includes(offer.id)} />
                                    <ListItemText primary={`${offer.name} - ${offer.Partner.name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <button className={loading ? 'btn btnSecondary' : 'btn btnPrimary'} disabled={loading ? 'disabled' : ''} type='submit'>{path.pathname === `/users/${params.id}` ? 'Save' : 'Create'}</button>
                    {path.pathname === `/users/${params.id}` && <div onDoubleClick={!loading && submitRemoveHandler} className={loading ? 'btn btnSecondary' : 'btn btnDanger'}>Delete</div>}
                </form>
            </Box>}
        </div >
    )
}

export default User