import React from 'react'
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { javascript } from '@codemirror/lang-javascript';
import s from './tutorial.module.scss'

const AutologinTutorial = () => {
    return <div className='card'>
        <div className={s.main}>Tilda:
            <div>1. Настраиваем тильду для <b>автологина</b></div>
            <div>На страничке <b>"Спасибо"</b> переходим в редактирование и добавляем новый блок</div>
            <img src={require('../../images/tilda-1.png')} />
            <br />
            <div>2. В открывшимся окне выбираем <b>"Другое"</b> (пункт 1. на скрине)</div>
            <div>В списке блоков (справа) выбираем <b>T123  HTML-код</b> </div>
            <img src={require('../../images/tilda-2.png')} />
            <br />
            <div>3. В появившемся окне жмем <b>"Контент"</b></div>
            <img src={require('../../images/tilda-3.png')} />

            <div>4. Вставляем код (текст кода можно скопировать ниже) в поле <b>HTML КОД / КОД ВИДЖЕТА</b></div>
            <img src={require('../../images/tilda-4.png')} />
            <div>5. Жмем <b>Сохранить и закрыть</b></div>
            <img src={require('../../images/tilda-5.png')} />
            <div>6. И после <b>Опубликовать</b></div>
            <img src={require('../../images/tilda-6.png')} />
            <br />

            <h1>КОД ДЛЯ ВСТАВКИ НА СТРАНИЧКУ СПАСИБО</h1>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', margin: '0 auto', fontSize: '1.4em' }}
                value={`<script>
    const urlParams = new URLSearchParams(window.location.search);
    const autologin = urlParams.get('autologin');
    const a = window.location;
    if(!!autologin)
        a.href = autologin;
</script>`}
                extensions={[javascript({ jsx: true })]}
            />

        </div>
    </div>
}

export default AutologinTutorial