import { Avatar, Box, Button, Card, Modal, CardContent, Grid, InputBase, Typography, Paper, TablePagination, Dialog, DialogTitle, DialogContent, Table, TableHead, TableRow, TableCell, TableBody, DialogActions } from '@mui/material'
import React from 'react'
import { useHttp } from '../../hooks/http.hook'
import useAuth from '../../hooks/useAuth.hook'
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import ReactCodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import "chartjs-adapter-date-fns";
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    TimeScale
);


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const options = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true
        },
        x: {
            type: 'time',
            time: {
                unit: 'day',
            }
        }
    },
    plugins: {
        tooltip: true,
        zoom: {
            pan: {
                enabled: true,
                mode: 'xy',
                modifierKey: 'ctrl',
            },
            zoom: {
                drag: {
                    enabled: true
                },
                mode: 'x',
            },
            reset: {
                enabled: true,
                text: 'Reset'
            }
        }
    },
    onClick: (evt, chart) => {
        const { zoom } = chart.chartInstance;
        zoom.resetZoom();
    }

    // plugins: {
    //     zoom: {
    //         pan: {
    //             enabled: true,
    //             mode: 'x',
    //         },
    //         zoom: {
    //             wheel: {
    //                 enabled: true,
    //             },
    //             pinch: {
    //                 enabled: true
    //             },
    //             mode: 'x',
    //             onZoomComplete({ chart }) {
    //                 chart.update('none');
    //             }
    //         }
    //     }
    // }
};


const Tracker = () => {
    const { request } = useHttp()
    const [trackers, setTrackers] = React.useState([])
    const [selectId, setSelectId] = React.useState('')
    const [nameFilter, setNameFilter] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [trackerStats, setTrackerStats] = React.useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [viewType, setViewType] = React.useState('table'); // тип представления: table или line

    const startTableRef = React.useRef(null);
    const chartRef = React.useRef(null);

    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    const handleViewTypeChange = (event, newViewType) => {
        if (newViewType !== null) {
            setViewType(newViewType);
        }
    };

    const handleInfoOpen = (id) => {
        setSelectId(id)
        getStats(id).then(res => {
            const mergedData = _.merge(_.keyBy(res.trackerStatsClick, 'date'), _.keyBy(res.trackerStatsLeads, 'date'));
            const result = _.map(mergedData, (value, key) => {
                return {
                    date: key,
                    countClick: value.countClick || 0,
                    id: value.id || null,
                    countLeads: value.countLeads || 0
                };
            });
            const sortedResult = _.sortBy(result, ['date']);
            setTrackerStats(sortedResult)
        })


        setOpenInfo(true);
    }

    const handleOpen = (id) => {
        setSelectId(id)
        setOpen(true);
    }
    const handleClose = () => {
        setSelectId('')
        setOpen(false);
    }

    const handleCloseInfo = () => {
        setSelectId('')
        setOpenInfo(false);
    }

    const handleRemove = async (id) => {
        try {
            const res = await request('/api/main/remove/tracker', 'POST', { id })
            if (res.tracker) {
                const newTracker = trackers.filter(el => el.id !== id)
                setTrackers(newTracker)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getStats = async (id) => {
        const res = await request(`/api/main/traker/stats/${id}`)
        return res
    }

    const getTrackerList = async () => {
        const res = await request(`/api/main/get/tracker`)
        setTrackers(res.tracker)
    }

    React.useEffect(() => {
        getTrackerList()
    }, [])

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleScrollToTableStart()
    };

    const handleScrollToTableStart = () => {
        startTableRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredLeadsCount = () => {
        return trackers.filter(el => el.Link.name.includes(nameFilter) || el.LinkId.includes(nameFilter)).length
    }

    return (
        <div className='card' style={{ padding: '1em' }}>
            <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                <InputBase
                    type="search"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By Name"
                    onChange={nameFilterHandler}
                />
            </Paper>

            <Box
                gap={2}
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
                ref={startTableRef}
            >
                <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[50, 100, 150, 300, 400, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={filteredLeadsCount()}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                {!!trackers.length && trackers.filter(el => el.Link.name.includes(nameFilter) || el.LinkId.includes(nameFilter))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(el => <Card sx={{ maxWidth: '32%' }} style={{ textAlign: 'left' }}>
                        <CardContent>
                            <div style={{ display: 'flex' }}>
                                <Typography color="text.secondary">
                                    ID: <b>{el.LinkId}</b>
                                </Typography>
                                <DeleteIcon className='btn' style={{ fill: 'red', zIndex: 9 }} onDoubleClick={e => { handleRemove(el.id) }} />
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Typography color="text.secondary">
                                        Link Name: <b>{el.Link.name}</b>
                                    </Typography>

                                    <Typography color="text.secondary">
                                        Updated At: <b>{new Date(el['updatedAt']).toLocaleString('uk-UA')}</b>
                                    </Typography>

                                    <Typography color="text.secondary">
                                        CR: {el.Link.approvedLeads ? <b>{el.Link.approvedLeads} | {((el.Link.approvedLeads / el.counter) * 100).toFixed(2)}%</b> : 0}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} justifySelf={'center'} alignSelf={'center'}>
                                    <Typography component={'div'} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar sx={{ bgcolor: green[500] }} >{el.counter}</Avatar>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Button size="small" onClick={() => { handleInfoOpen(el.LinkId) }}>Get Info</Button>

                            <Button size="small" onClick={() => {
                                handleOpen(el.LinkId)
                            }
                            }>Get Tracker</Button>
                        </CardContent>
                    </Card>)}

                <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[50, 100, 150, 300, 400, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={filteredLeadsCount()}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Tracker for link: <b>{selectId}</b>
                        </Typography>
                        <ReactCodeMirror
                            width="max-content"
                            style={{ textAlign: 'left', margin: '0 auto', fontSize: '1.4em' }}
                            value={`<script src="${process.env.REACT_APP_URL}/static/side_script/tracker.js"></script>
<script>
    btrc('${selectId}');
</script>`}
                            extensions={[javascript({ jsx: true })]}
                        />
                        <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(`<script src="${process.env.REACT_APP_URL}/static/side_script/tracker.js"></script><script>btrc('${selectId}');</script>`) }}>Copy</Button>
                    </Box>
                </Modal>
            </Box>

            <Dialog sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '100%'
                }
            }} open={openInfo} onClose={handleCloseInfo}>
                <DialogTitle>Tracker Statistics</DialogTitle>
                <DialogActions sx={{ justifyContent: 'flex-start' }}>
                    <ToggleButtonGroup
                        value={viewType}
                        exclusive
                        onChange={handleViewTypeChange}
                        aria-label="View type"
                    >
                        <ToggleButton value="table" aria-label="Table">
                            Table
                        </ToggleButton>
                        <ToggleButton value="line" aria-label="Line">
                            Chart
                        </ToggleButton>

                    </ToggleButtonGroup>

                    {viewType === 'line' && <Button sx={{ ml: 'auto' }} variant="outlined" onClick={handleResetZoom}>Reset Zoom</Button >}

                </DialogActions>
                <DialogContent>

                    {viewType === 'table' && (
                        <Table>
                            <TableHead>
                                <TableRow sx={{
                                    position: 'sticky',
                                    top: '-21px',
                                    backgroundColor: 'rgb(196 195 195)',
                                    zIndex: 1,
                                    '& th': {
                                        padding: '2px',
                                        '& > span': {
                                            display: 'inline-flex',
                                            marginRight: '16px',
                                            marginLeft: '16px'
                                        }
                                    }
                                }}>
                                    <TableCell><span style={{ width: '75px' }}>Date</span>  - <span style={{ width: '50px', justifyContent: 'center' }}>Clicks</span> - <span style={{ width: '50px', justifyContent: 'center' }}>Leads</span> - <span style={{ width: '50px', justifyContent: 'center' }}>CR</span> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!trackerStats.length && trackerStats.map((stat, index) => (
                                    <TableRow key={stat.date} sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: '#f2f2f2'
                                        },
                                        '&:hover': {
                                            backgroundColor: '#e6e6e6'
                                        },
                                        '& td': {
                                            padding: '2px',
                                            whiteSpace: 'nowrap',
                                            '& > span': {
                                                display: 'inline-flex',
                                                marginRight: '16px',
                                                marginLeft: '16px',
                                                justifyContent: 'center'
                                            }
                                        }
                                    }}>
                                        <TableCell><span style={{ width: '75px' }}>{stat.date}</span>  -  <span style={{ width: '50px' }}>{stat.countClick}</span>  -  <span style={{ width: '50px' }}>{stat.countLeads}</span> -  <span style={{ width: '50px' }}>{isFinite(stat.countLeads / stat.countClick) ? ((stat.countLeads / stat.countClick) * 100).toFixed(2) : stat.countLeads}%</span></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    )}

                    {viewType === 'line' && (
                        <div style={{ margin: '0', display: 'flex', flexDirection: 'column' }}>
                            <Line style={{ width: '100%' }} data={{
                                labels: trackerStats.map(stat => stat.date),
                                datasets: [
                                    {
                                        label: 'Clicks',
                                        data: trackerStats.map(stat => stat.countClick),
                                        fill: false,
                                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                                        borderColor: 'rgba(54, 162, 235, 1)',
                                        borderWidth: 1
                                    },
                                    {
                                        label: 'Leads',
                                        data: trackerStats.map(stat => stat.countLeads),
                                        fill: false,
                                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                        borderColor: 'rgba(255, 99, 132, 1)',
                                        borderWidth: 1
                                    },
                                    {
                                        label: 'CR',
                                        data: trackerStats.map(stat => {
                                            if (isFinite(stat.countLeads / stat.countClick)) {
                                                return ((stat.countLeads / stat.countClick) * 100).toFixed(2)
                                            } else {
                                                return stat.countLeads
                                            }
                                        }),
                                        fill: false,
                                        backgroundColor: 'rgba(75, 192, 192, 0.5)',
                                        borderColor: 'rgba(75, 192, 192, 1)',
                                        borderWidth: 1
                                    }
                                ]
                            }} options={options} height={'100%'} ref={chartRef}
                                onDoubleClick={handleResetZoom} />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfo}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default Tracker