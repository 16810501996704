import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useHttp } from "../../hooks/http.hook";
import TableWrap from "../components/Table/TabelWrap";
import SimpleModal from '../components/SimpleModal/SimpleModal'
import tableCss from '../components/Table/style.module.scss'
import { flatten } from 'flat'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth.hook";
import { InputBase } from "@mui/material";
import Preloader from '../components/Preloader'
import { Tooltip } from 'react-tooltip'
import LeadItem from "./LeadItem";
import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const Holder = () => {
    const auth = useAuth()
    const { request, loading } = useHttp();
    const { request: sendLeadReq, loading: sendLeadLoad } = useHttp();
    const { request: deleteLeadReq, loading: deleteLeadLoad } = useHttp();

    const [leads, setLeads] = React.useState([])
    const [nameFilter, setNameFilter] = React.useState('')

    const headers = ['Created At', 'First Name', 'Last Name', 'Email', 'Phone', 'Ref', 'Link']

    const getLeads = async (e) => {
        const res = await request(`/api/main/holder/leads`)
        setLeads(res)
    }

    React.useEffect(() => {
        getLeads()
    }, [])

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }


    const handleDelete = React.useCallback(async (id) => {
        try {
            await deleteLeadReq(`/api/main/holder/remove/lead/${id}`, 'POST', {}, { Authorization: `Bearer ${auth.token}` });
            setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
        } catch (error) {
            console.error(error);
        }
    }, [auth.token, deleteLeadReq]);

    const sendLeadNow = React.useCallback(async (id) => {
        toast.promise(
            sendLeadReq(`/api/main/holder/send/lead/${id}`, 'GET', null, { Authorization: `Bearer ${auth.token}` })
                .then(res => {
                    const { resData } = res;
                    if (resData.statusCode === 200) {
                        setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
                        return 'Lead sent successfully';
                    } else {
                        throw new Error('Lead sending failed');
                    }
                }),
            {
                pending: 'Sending lead...',
                success: {
                    render({ data }) {
                        return data;
                    },
                    autoClose: 1000, // Устанавливаем время отображения на 500 мс
                },
                error: {
                    render({ data }) {
                        return data;
                    },
                    autoClose: 1000, // Устанавливаем время отображения на 500 мс
                },
            }
        ).catch(error => {
            console.error(error);
        });
    }, [auth.token, sendLeadReq]);

    return <div className="card" >
        <h2>Leads in Holder</h2>
        <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
            <InputBase
                type="search"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Name, Last Name or Email"
                onChange={nameFilterHandler}
                value={nameFilter}
            />
        </Paper>
        {loading && <Preloader />}
        {!loading && leads.length === 0 && <h2>No Results</h2>}
        {!loading && leads.length > 0 && <TableWrap>
            <div className={tableCss.table}>
                <div className={tableCss.header} style={{ "gridTemplateColumns": `90px repeat(4,minmax(100px, 1fr)) 200px 100px 90px 120px` }}>
                    {headers.map(header => <div>
                        {header}
                    </div>)}
                </div>
                <div className={tableCss.content}>
                    {leads
                        .filter(lead => (lead.first_name + " " + lead.last_name + " " + lead.email).includes(nameFilter))
                        .map((lead, index) => (
                            <LeadItem
                                key={lead.id + '-' + index}
                                lead={lead}
                                index={index}
                                handleDelete={handleDelete}
                                sendLeadNow={sendLeadNow}
                            />
                        ))}
                </div>
            </div>
        </TableWrap>}
    </div>
}

export default Holder