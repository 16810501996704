import React from 'react'
import s from './style.module.scss'

const TableWrap = (props, { children }) => {
  return <div className={s.tableWrap} >
    <div {...props}>{props.children}</div>
  </div>
}

export default TableWrap;
