import React from 'react'
import Countdown from "react-countdown"

// Random component
const Completionist = () => <span>....</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a complete state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <span>
                {seconds}
            </span>
        );
    }
};

const LeadRedirect = () => {

    const style = {
        color: '#000000',
        fontSize: '37px',
        fontFamily: 'Arial,Arial,sans-serif',
        lineHeight: '57px',
        width: '50%',
        fontWeight: 400,
        backgroundPosition: 'center center',
        borderColor: 'transparent',
        borderStyle: 'solid',
    }
    return <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={style}>Success! Redirect through <Countdown date={Date.now() + 5000} renderer={renderer} />.</div>
    </div>
}

export default LeadRedirect