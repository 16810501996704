import React, { useCallback } from "react";

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHttp } from '../../../hooks/http.hook'

const TrafficFilterSwitch = () => {
    const { request, loading } = useHttp()
    const [status, setStatus] = React.useState(false)

    const getStatus = useCallback(async () => {
        try {
            const data = await request(`/api/utils/status`, 'GET')
            if (data.success === true) {
                setStatus(data.filterEnabled)
            }
            console.log(data)
        } catch (e) {
            console.log(e)
        }
    }, [])

    const setStatusIsActive = useCallback(async (status) => {
        try {
            const data = await request(`/api/utils/toggle-filter`, 'POST', {
                enabled: status
            })

            if (data.success === true) {
                setStatus(status)
            }
            console.log(data)
        } catch (e) {
            console.log(e)
        }
    }, [])

    React.useEffect(() => {
        getStatus()
    }, [])

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <FormControlLabel
            sx={{ marginLeft: '20px' }}
            control={
                <Switch checked={status} onChange={() => setStatusIsActive(!status)} />
            }
            label="Traffic Filter"
        />
    );
};

export default TrafficFilterSwitch;