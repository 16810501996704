import React from 'react'
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { javascript } from '@codemirror/lang-javascript';
import s from './tutorial.module.scss'

const FormTutorial = () => {
    return <div className='card'>
        <div className={s.main}>Настройка отправки формы на СРМку:
            <div>1. Настраиваем отпрвку форм в нашу <b>СРМку</b></div>
            <div>На страничке с <b>формами</b> переходим в редактирование</div>
            <div>Находим блок с нашей формой и жмем <b>"Контент"</b></div>

            <img src={require('../../images/form-1.png')} />
            <br />
            <div>2. В открывшимся окне ищем пункт <b>"ПРИЕМ ДАННЫХ ИЗ ФОРМЫ"</b> и дважды кликаем по этой надписи (цифра 1. на скрине)</div>
            <div>3. выбираем пункт <b>Свой скрипт для принятия данных</b> (цифра 2 на скрине)<br/>
            <img src={require('../../images/form-4.png')} />
            4. В поле для вставки вставляем нужный линк из <b>НАШЕЙ СРМки</b><br />
            Линк берем в <b>НАШЕЙ СРМки</b> (на скрине пример где брать линк, вы берете свой, но все в той же СРМке)<br />
            <img src={require('../../images/inputs-3.png')} />
            5. <b>ЦЕЛЬ ФОРМЫ</b> выбираем <b>В этом же окне</b><br/>
            6. <b>СНИМАЕМ</b> галочку с <b>посылать данные без перезагрузки страницы (AJAX)</b> - чекбокс должен быть не активен как на скрине</div>
            <img src={require('../../images/form-2.png')} />
            <br />
            <div>7. Жмем <b>Сохранить и закрыть</b></div>
            <img src={require('../../images/form-3.png')} />
            <div>8. И после <b>Опубликовать</b></div>
            <img src={require('../../images/tilda-6.png')} />
            <br />

            <h1><span style={{color: 'red'}}>ВАЖНО:</span> эту операцию нужно проделать с каждой формой на странице!</h1>           
        </div>
    </div>
}

export default FormTutorial