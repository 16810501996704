import React from 'react'
import { Link, Outlet } from 'react-router-dom'
const Tutorial = ({auth }) => {
    return <>
        <h2>Tutorials</h2>
        <nav>
            <Link className='btn btnLink' to="partner-integration">Интеграция с ПП</Link>
            <Link className='btn btnLink' to="affiliate-integration">Ленд с СРМ</Link>
            <Link className='btn btnLink' to="autologin">Настройка Автологина</Link>
            <Link className='btn btnLink' to="form">Отправки на СРМ</Link>
            <Link className='btn btnLink' to="inputs">Поля Формы</Link>
            <Link className='btn btnLink' to="domains-to-tilda">Подключение домена к тильде</Link>
            <Link className='btn btnLink' to="traker-utm-fb">СРМ трекер / UTM / FB pixel</Link>
        </nav>
        <Outlet />
    </>
}

export default Tutorial