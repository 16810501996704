import Modal from 'react-modal';
import React, { useEffect } from 'react';
import { pad } from 'lodash';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%',
        padding: '20px',
        zIndex: 999
    },
};

const UrlTooltip = ({ url }) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [parsedUrl, setParsedUrl] = React.useState({
        origin: '',
        pathname: '',
    });
    const [params, setParams] = React.useState([]);
    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }
    function isValidURL(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }



    const modalContent = Array.from(params.entries()).map(([key, value], index) => (
        <div key={index}>
            <b>{key}:</b> {value}
        </div>
    ));

    useEffect(() => {
        if (isValidURL(url)) {
            try {
                const parsedUrl = new URL(url);
                const params = new URLSearchParams(parsedUrl.search);
                setParsedUrl(parsedUrl);
                setParams(params);
            } catch (e) {
                console.log(e, url);
            }
        }
    }, [url]);
    return (
        <div>
            <div style={{
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
            }} onClick={openModal}>{url}</div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Parsed URL"
            >
                {/* Add URL without params */}
                <div>
                    <b>URL:</b> {parsedUrl.origin + parsedUrl.pathname}
                </div>
                {modalContent}
            </Modal>
        </div>
    );
}

export default UrlTooltip;