import React from "react";

function FontSizeSlider({ fontSize, onChange }) {
    return (
        <div className="font-size-slider" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ width: '100%', textAlign: 'left' }}>
                <label htmlFor="font-size-range">Font size:</label>
                <span>{fontSize}px</span>
            </div>
            <input
                style={{ width: '100%' }}
                type="range"
                min="8"
                max="36"
                id="font-size-range"
                value={fontSize}
                onChange={(event) => onChange(parseInt(event.target.value))}
            />
        </div>
    );
}

export default FontSizeSlider;
