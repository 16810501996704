import React from 'react'
import _ from 'lodash'
import s from './style.module.scss'

const TableCell = ({ cell }) => {
    return <div className={s.cell}>
        {cell}
    </div>
}


const TableRow = ({ row, hide }) => {
    return <div className={s.row} style={{ "gridTemplateColumns": `repeat(${Object.keys(row).length - hide.length}, minmax(auto, ${100/Object.keys(row).length}%))` }}>
        {Object.keys(row).map((cell, i) => !hide.includes(cell) && <TableCell key={`${cell}-${i}`} cell={row[cell]} />)}
    </div>
}

const TableHead = ({ row, hide }) => {
    return <div className={`${s.row} ${s.header}`} style={{ "gridTemplateColumns": `repeat(${Object.keys(row).length - hide.length}, minmax(auto, ${100/Object.keys(row).length}%))` }}>
        {Object.keys(row).map((cell, i) => !hide.includes(cell) && <TableCell key={`${cell}-${i}`} cell={cell} />)}
    </div>
}

const Table = ({ data, headers = {}, hide = [] }) => {


    return <div className={s.body}>
        <TableHead hide={hide} row={_.isEmpty(headers) ? data[0] : headers} />
        {data.map((row, i) => <TableRow key={`TableRow-${i}`}  row={row} hide={hide} />)}
    </div>
}

export default Table