import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NumberDetails from "../components/NumberDetails/NumberDetails";
import s from './s.module.scss'
import UrlTooltip from "../components/UrlTooltip";

const LeadRow = ({
    lead,
    index,
    page,
    rowsPerPage,
    fontSize,
    handleDelete,
    setQuality,
    modal,
    setModal,
    setDetails,
    selectedIds,
    handleCheckboxChange,
    auth,
    detailsHandler,
    headers
}) => {
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    const toggleHistory = () => {
        setIsHistoryOpen(!isHistoryOpen);
    };

    return (
        <>
            <div
                key={lead.id}
                className={`${s.row} ${lead.postback_status === 1 ? 'pb-success' : lead.postback_status === 2 ? 'pb-reject' : ''}`}
                style={{
                    gridTemplateColumns: `25px 40px ${70 + fontSize}px repeat(9,minmax(50px, 1fr)) ${90 + fontSize}px ${90 + fontSize}px 25px`,
                    padding: '8px 0'
                }}
            >
                {/* Удаление лида */}
                <div onDoubleClick={() => handleDelete(lead.id)} style={{ color: 'red', cursor: 'pointer' }}>✕</div>

                {/* Номер строки */}
                <div>{page * rowsPerPage + index + 1}</div>

                {/* Дата создания */}
                <div>{new Date(lead['createdAt']).toLocaleString('uk-UA')}</div>

                {/* Имя с возможностью установки качества */}
                <div style={{ cursor: 'pointer' }} onClick={() => setQuality(lead.id)}>
                    {lead['first_name']}
                    <div title="Помітити лід високої якості" style={{ color: 'green' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: 24, height: 24, fill: lead.quality ? 'green' : 'white' }} className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                        </svg>
                    </div>
                </div>

                {/* Фамилия */}
                <div>{lead['last_name']}</div>

                {/* Email */}
                <div>{lead['email']}</div>

                {/* Телефон */}
                <div><NumberDetails phoneNumber={lead['phone']} /></div>

                {/* Результат */}
                <div className={s.results}>
                    {lead['result']}
                    {/* Кнопка для отображения истории */}
                    {lead['statusHistories'].length > 0 && <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={toggleHistory}>
                        {isHistoryOpen ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={s.size_2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                        </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={s.size_2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        }
                    </div>}
                </div>

                {/* Ссылка с тултипом */}
                <div style={{
                    maxHeight: 25,
                    overflow: 'hidden',
                    position: 'relative',
                }}>
                    <UrlTooltip url={lead['ref']} />
                </div>

                {/* Статус */}
                <div className={
                    lead['status'] === 'sent' ? 'message message-warning' :
                        lead['status'] === 'approved' ? 'message message-success' :
                            lead['status'] === 'duplicate' ? 'message message-danger' : ''}
                    style={{ fontSize, margin: 0 }}>
                    {lead['status']}
                </div>

                {/* IP */}
                <div>{lead['ip']}</div>

                {/* UTM и Offer */}
                <div>
                    {lead['utm'] && <div style={{ color: '#337ab7' }}>{lead['utm']}</div>}
                    <Link to={`/offers/${lead['Link'].Offer.id}/add?search=${(lead?.Link?.offer_name ?? '')}`} >
                        {lead['Link.offer_name'] || (lead?.Link?.offer_name ?? '')}
                    </Link>
                    {lead['campaign'] && <div style={{ color: '#b73333', fontSize: '12px' }}>{lead['campaign']}</div>}

                    {/* Approved Lead Count */}
                    <div style={{ fontSize: fontSize + 4 }}>
                        <sup style={{ padding: "1px 3px" }} className="message-success">{lead?.Link?.approvedLeadCount}</sup>/
                        <sub style={{ padding: "1px 3px" }} className="message-warning">{lead['leadLinkCount']}</sub>
                    </div>
                </div>

                {/* Gone и Другие параметры */}
                <div className={`message ${detailsHandler(lead['gone'])}`} style={{
                    margin: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize,
                    position: 'relative'
                }} title={`${lead['gone']}`}>
                    {lead?.other_params?.headless && <div style={{ color: 'rgb(51, 122, 183)', position: 'absolute', top: '3px', width: 'calc(100% - 30px)', fontSize: '10px', color: '#337ab7' }}>holder</div>}
                    {lead['gone']}
                </div>

                {/* Кнопка Details */}
                <div style={{ fontSize }} className="btn btnLink" onClick={() => {
                    setModal({ ...modal, modal: true });
                    setDetails(lead['other_params'])
                }}>
                    Details
                </div>

                {/* Checkbox для админов */}
                {!!auth.user?.isAdmin && lead.ref &&
                    <label style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', cursor: 'pointer' }}>
                        <input
                            style={{ cursor: 'pointer', width: '100%', height: '100%', }}
                            type="checkbox"
                            checked={selectedIds.map(el => el.id).includes(lead.id)}
                            onChange={(e) => handleCheckboxChange(e, lead.id)}
                        />
                    </label>
                }


            </div>

            {/* Исторические данные */}
            {isHistoryOpen && lead.statusHistories.length > 0 && (
                <div className={s.history} style={{ padding: '8px 16px', backgroundColor: '#f9f9f9' }}>
                    <h4>History Status:</h4>
                    <div>
                        {lead.statusHistories.map(history => (
                            <div key={history.id}>
                                <strong>{new Date(history.createdAt).toLocaleString('uk-UA')}:</strong> {history.old_value} → {history.new_value}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

LeadRow.propTypes = {
    lead: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    fontSize: PropTypes.number.isRequired,
    handleDelete: PropTypes.func.isRequired,
    setQuality: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    detailsHandler: PropTypes.func.isRequired,
    headers: PropTypes.object.isRequired,
};

export default LeadRow;
