import * as React from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ReactCountryFlag from 'react-country-flag';

function NumberDetails({ phoneNumber }) {
  const formattedPhoneNumber = `+${phoneNumber.replace(/\D/g, '')}`;
  const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber);
  const countryCode = parsedPhoneNumber?.country || '';

  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {countryCode && <ReactCountryFlag title={countryCode} countryCode={countryCode} svg style={{ boxShadow: 'none', margin: '0' }} />}
    <span>{phoneNumber}</span>
  </div>
}

export default NumberDetails;