import React from 'react'
import s from './tutorial.module.scss'

const DomainsToTilda = () => {
    return <div className='card'>
        <div className={s.main}>
            <h2>Добавление домена на аккаунт хостинга:</h2>
            <div>1. Логинимся
                <br />
                <a href='https://customers.asmallorange.com/index.php?rp=/login' target='_blank'>Ссылка на стрнаницу логина*</a></div>

            <img src={require('../../images/domain1.png')} />

            <div>2. В выпадающем меню выбираем вкладку <b>My Services</b><br />
                <a href='https://customers.asmallorange.com/clientarea.php?action=services' target='_blank'>Ссылка на стрнаницу My Services*</a></div>
            <img src={require('../../images/domain2.png')} />

            <div>3. Выбираем наш активный аккаунт</div>
            <img src={require('../../images/domain-3.png')} />

            <div>4. В открывшимся окне снизу справа выбираем <b>Log in to cPanel</b></div>
            <img src={require('../../images/domain-4.png')} />

            <div>5. Ищем на страничке вкладку <b>Addon Domain</b> и открываем ее</div>
            <img src={require('../../images/domain-5.png')} />

            <div>6. Вставляем домен который хотим добавить в поле <b>New Domain Name</b> и клацаем по пустому месту на страничке чтоб снять фокус с поля, остальные поля заполнятся автоматически.
                <br />
                После заполнения жмем конпку <b>Add Domain</b>. Ждем конца загрузки страницы.</div>
            <img src={require('../../images/domain-6.png')} />

            <div>7. Такое окно 👇 значит, что домен добавился успешно (если увидели окно с ошибкой, не пытаемся добавить домен снова, берем другой).<br />
                Жмем <b>Go Back</b></div>
            <img src={require('../../images/domain-7.png')} />

            <div>8. Првоерили что домен появился в списке и жмем <b>Home</b> (икнока квадратиков в верхнем левом углу).</div>
            <img src={require('../../images/domain-8.png')} />

            <div>9. Во вкладке <b>Home</b> ищем вкладку <b>Zone Editor</b> и открываем ее.</div>
            <img src={require('../../images/domain-9.png')} />

            <div>10. Находим в списке наш новый домен и жмем <b>Manage</b>.</div>
            <img src={require('../../images/domain-10.png')} />

            <div>11. В этом окне переводим <b>Filter</b> в значение <b>A</b> (цифра <b>1.</b> на скрине).
                <br />Затем поочередно удаляем все записи что там есть (цифра <b>2.</b> на скрине).<br />
                <span className={s.ps}>все записи с Type: A.</span></div>
            <img src={require('../../images/domain-11.png')} />

            <div>12. В этом же окне переводим <b>Filter</b> в значение <b>CNAME</b>.
                <br />Находим запись нашего домена с <b>www</b> (подчеркнута на скрине) и удаляем ее.
                <br />Переводим <b>Filter</b> обратно в значение <b>A</b>.
                <br /><b>НЕ ЗАКРЫВАЕМ ВКЛАДКУ, ОНА НАМ ЕЩЕ ПРИГОДИТСЯ.</b>
            </div>
            <img src={require('../../images/domain-19.png')} />
            <br />
            <br />
            <h2>Настройка тильды:</h2>
            <div>13. Логинимся в тильде <a href='https://tilda.cc/login/' target='blank'>Tilda</a> </div>

            <div>14. Выбираем проект на который будем подключать домен.
                <span className={s.ps}> Проект Dima CA12 выбран для примера</span>
            </div>
            <img src={require('../../images/domain-12.png')} />

            <div>15. Переходим в <b>Настройки сайта</b>. </div>
            <img src={require('../../images/domain-13.png')} />

            <div>16. На странице в меню слева выбираем вкладку <b>Домены</b> (цифра 1. на скрине) и в открывшимся окне вводим домен который хотим добавить  (цифра 2. на скрине), и жмем <b>Сохранить изменения</b>  (цифра 3. на скрине).</div>
            <img src={require('../../images/domain-15.png')} />

            <div>17. Дальше страничка обновится, выдаст ошибку и IP который мы должны указать на аккаунте <b>ХОСТИНГА</b>.
                <br />Копируем IP который выдала тильда и возвращаемся на хостинг во вкладку <b>Zone Editor</b>
                <br /><b>НЕ ЗАКРЫВАЕМ ВКЛАДКУ, ОНА НАМ ЕЩЕ ПРИГОДИТСЯ.</b>
            </div>
            <img src={require('../../images/domain-16.png')} />


            <br /><br />
            <h2>Возвращаемся на хостинг во вкалдку из пункта 11.</h2>

            <div>18.  Цифра <b>1</b> на скрине - жмем <b>Add Record</b> у нас появится новая запись.
                <br /><br />Цифра <b>2</b> на скрине - вводим имя нашего домена <b>С ТОЧКОЙ В КОНЦЕ (ПРИМЕР: addictionclinicdirectory.com.</b> Лишние пробелы в конце и начале строки лучше убрать если они есть.
                <br /><br />Цифра <b>3</b> на скрине - проверяем чтоб <b>Type</b> был <b>A</b>
                <br /><br />Цифра <b>4</b> на скрине - вводим IP который нам выдала тильда на страничке добавления домена. Так же лучше убрать все лишние пробелы в конце и начале строки, если они есть.
                <br /><br />Цифра <b>5</b> на скрине - жмем <b>Add Record</b>
            </div>
            <img src={require('../../images/domain-17.png')} />

            <div>19. Повторяем пункт <b>18.</b> и в поле <b>Name</b> вводим наш домен с <b>www.</b> в начале. <b>(ПРИМЕР: www.addictionclinicdirectory.com.</b>)
                <br /><span className={s.ps}>Не забываем про точку в конце домена</span></div>
            <img src={require('../../images/domain-18.png')} />

            <div>20. В конечно итоге имеем две записи <b>Type: A</b></div>
            <img src={require('../../images/domain-20.png')} />

            <div>21. Возвращаемся к <b>Тильде</b> на страничку из пункта 17. и жмем <b>Проверить подключение домена</b>.</div>
            <img src={require('../../images/domain-21.png')} />

            <div>22. В конечном итоге должны увидеть что все успешно подключилось.</div>
            <img src={require('../../images/domain-22.png')} />

            <h2>Настраиваем HTTPS</h2>
            <div>23. На странице в меню слева выбираем <b>SEO</b> и в открывшимся окне под пунктом <b>НАСТРОЙКА HTTPS</b> жмем <b>Редактировать</b>. </div>
            <img src={require('../../images/domain-14.png')} />

            <div>24. Скорее всего мы увидем сообщение об ошбике как на скрине. Не пугаемся, просто еще не обновились глобальные настройки которые от нас не зависят. Возвращаемся к этому пункту через несколько часов.</div>
            <img src={require('../../images/domain-23.png')} />

            <div>25. После того как глобальные настройки будут обновлены мы увидем на страничке переключатель у вас он будет выключен - включем его (он станет в положение посередине, будет и не включен и не выключен, это значит что сертификат устанавливается) и через 2-3 мин обновляем страниц - HTTPS будет готов.</div>
            <img src={require('../../images/domain-24.png')} />

            <div>27. После того как тумблер станет в положение "включен" жмем на кнопку <b>Закрыть</b>.</div>

            <div>28. Во вкладке <b>SEO</b> прокручиваем страницу ниже и ищем пункт <b>РЕДИРЕКТЫ ДЛЯ WWW, HTTPS</b> - жмем "Редактировать".</div>
            <img src={require('../../images/domain-27.png')} />
            
            <div>29. Ищем пункт <b>РЕДИРЕКТ С HTTP НА HTTPS ДЛЯ ДОМЕНА</b> и переключаем в положение <b>Редирект с HTTP на HTTPS</b> - жмем "Сохранить".</div>
            <img src={require('../../images/domain-28.png')} />

            <div>Все изменения могут вступать в силу не сразу т.к. мы изменяем глобальные значения доменов и у кждого провайдера интервал обновления данных свой.</div>
        </div>
    </div>
}

export default DomainsToTilda