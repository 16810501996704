import React from 'react'
import s from './tutorial.module.scss'

const InputsTutorial = () => {
    return <div className='card'>
        <div className={s.main}>Правила именования полей формы:
            <div>1. В режиме редактирования в тильде находим блок с формой и нажимаем <b>Контент</b></div>
            <img src={require('../../images/form-1.png')} />

            <div>2. В открывшимся окне выбираем пункт <b>ПОЛЯ ДЛЯ ВВОДА</b></div>
            <img src={require('../../images/inputs-1.png')} />

            <div>
                3. Здесь перечислены все поля формы у нас их <b>4</b>:
                Имя, Фамилия, Почта и телефон
            </div>

            <div>
                В каждом поле нас интерисуют пункты <b>ИМЯ ПЕРЕМЕННОЙ</b> и <b>Обязательно для заполнения</b><br /><br />
                <b>Обязательно для заполнения</b> - ставим галочку для всех 4 полей (Имя, Фамилия, Почта и телефон)
                <br /><br />
                <b>ИМЯ ПЕРЕМЕННОЙ</b> ставим для каждого поля в соотвествии со списком<br />

                Поле:<b>Имя (Name)</b> - ставим имя переменной <b>first_name</b><br />
                Поле:<b>Фамилия (Second Name / Surname)</b> - ставим имя переменной <b>last_name</b><br />
                Поле:<b>Email</b> - ставим имя переменной <b>email</b><br />
                Поле:<b>Телефон</b> - ставим имя переменной <b>phone</b><br />
            </div>
            <img src={require('../../images/inputs-2.png')} />

            <div>5. Жмем <b>Сохранить и закрыть</b></div>
            <img src={require('../../images/form-3.png')} />
            <div>6. И после <b>Опубликовать</b></div>
            <img src={require('../../images/tilda-6.png')} />
            <br />

            <div><span style={{ color: 'red' }}>ВАЖНО:</span> <b>ИМЯ ПЕРЕМЕННОЙ</b> должно быть точно таким как в туториале, с маленькой буквы.<br/> 
            З.Ы. Если в туториале написано <b>ИМЯ ПЕРЕМЕННОЙ</b> - email, то Email нам не подходим, лид не попадет в СРМку и будет утрачен!!!</div>
        </div>
    </div>
}

export default InputsTutorial