import React from 'react'
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { javascript } from '@codemirror/lang-javascript';
import s from './tutorial.module.scss'
import { Link } from 'react-router-dom';

const TrakerAndUtm = () => {
    return <div className='card'>
        <div className={s.main}>Tilda:
            <h3>1. Устанавливаем <b>трекер</b> и отслеживание <b>UTM</b> на тильде</h3>
            <div>В проекте заходим в  <b>Настройки сайта</b></div>
            <img src={require('../../images/traker/1.png')} />
            <br />
            <h3>2. В открывшимся окне в левом сайтбаре выбираем <b>"еще"</b>, в првом окне ищем <b>HTML-КОД ДЛЯ ВСТАВКИ ВНУТРЬ HEAD</b> и нажимаем</h3>
            <img src={require('../../images/traker/2.png')} />
            <br />
            <h3>3. В появившемся окне подключаем наши скрипты</h3>
            <img src={require('../../images/traker/6.png')} />
            <h4>3.1 Подключение файла со скриптами</h4>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', margin: '0', fontSize: '1.4em' }}
                value={`<script src="${process.env.REACT_APP_URL}/static/side_script/tracker.js"></script>`}
                extensions={[javascript({ jsx: true })]}
            />
            <h4>3.2 Подключение скрипта трекера из нашей СРМ <Link to="/trackers">Tracker</Link></h4>
            <div>Код для вставки в функцию берем в СРМ. Это код линка который мы игнтегрируем в форму на сайте в тильде. (для каждой интеграции код свой)</div>
            <div>Код линка начинается полсе "m=": m=<b>03eff979-e7d8-4d56-ba50-5f41f0223cfe</b></div>
            <img src={require('../../images/traker/3.png')} />

            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', margin: '0', fontSize: '1.4em' }}
                value={`<script>btrc('СЮДА ВСТАВЛЯЕМ КОД ЛИНКА ИЗ СРМ');</script>`}
                extensions={[javascript({ jsx: true })]}
            />

            <h4>3.3 Подключение скрипта отслеживания UTM</h4>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', margin: '0', fontSize: '1.4em' }}
                value={`<script>blrsUTM();</script>`}
                extensions={[javascript({ jsx: true })]}
            />

            <h3>4. Подключаем Facebook Pixel</h3>
            <div>Вставляем код пикселя в тоже поле что и наши скрипты</div>
            <div>Код берем в кабинете в фейсбуке</div>
            <div>Проверяем чтобы часть кода <b>fbq('track', 'PageView');</b> была как на скрине</div>
            <img src={require('../../images/traker/9.3b5661eac9b3d36beb31.png')} />
            <h3>ПРИМЕР ВСТАВЛЕНЫХ ТРЕКЕРА, UTM ЧЕКЕРА И ПИКСЕЛЯ ФЕЙСБУК </h3>
            <img src={require('../../images/traker/5.png')} />

            <h3>После того как мы все подклочюили жмем "сохранить"</h3>
            <img src={require('../../images/traker/7.png')} />
            <h3>После жмем "закрыть" и еще раз "закрыть"</h3>
            <h3>Подключаем отслеживание лидов</h3>
            <div>В проекте заходим на страничку  <b>TU</b></div>
            <img src={require('../../images/traker/13.png')} />
            <div>В открывшемся окне листаем вниз и находим блок с кодом для автологина и жмем <b>Контент</b></div>
            <img src={require('../../images/traker/14.png')} />
            <div>В открывшемся окне вставляем код для отслеживания лидов (код ниже).<br />После того как вставили код жмем <b>Сохранить и закрыть</b></div>
            <img src={require('../../images/traker/15.png')} />
            <div><b>ВАЖНО!!!</b> Код вставляем над кодом автологина, как показано на скрине выше иначе автологин не будет отслеживаться.</div>
            <CodeMirror
                width="max-content"
                style={{ textAlign: 'left', margin: '0', fontSize: '1.4em' }}
                value={`<script>
    fbq('track', 'Lead');
</script>`}
                extensions={[javascript({ jsx: true })]}
            />
            <h3>После возвращаемся в проект</h3>
            <img src={require('../../images/traker/16.png')} />

            <div>Жмем <b>ОПУБЛИКОВАТЬ ВСЕ СТРАНИЦЫ</b></div>
            <img src={require('../../images/traker/8.png')} />

            <h3>После того как все страницы опубликованы мы можем проверить работу трекера, UTM и FB pixel</h3>

            <h3>Видеоинструкция</h3>
            <video style={{width: '100%',height: 'auto'}} controls="controls">
                <source src="/video/traker.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                Тег video не поддерживается вашим браузером.
                <a href="/video/traker.mp4">Скачайте видео</a>.
            </video>
        </div>
    </div>
}

export default TrakerAndUtm