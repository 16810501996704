import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth.hook'
// import s from './component.module.scss'

const Header = (props) => {
   const auth = useAuth()

   return !!auth.token ? <>
      {auth.user?.isAdmin ? <>
         <div id="loader" >
            <Link className='btn btnLink' to="/">Home</Link>
            <Link className='btn btnLink' to="/leads">Leads</Link>
            <Link className='btn btnLink' to="/holder">Holder Leads</Link>
            <Link className='btn btnLink' to="/resend">Resend Leads</Link>
            <Link className='btn btnLink' to="/tutorial">Tutorial</Link>
            <Link className='btn btnLink' to="/users">Users</Link>
            <Link className='btn btnLink' to="/trackers">Trackers</Link>
            <Link className='btn btnLink' to="/domains">Domains</Link>
            {/* <Link className='btn btnLink' to="/tutorial/autologin">Autologin</Link> */}
            <div className='btn btnLink' onClick={auth.logOut}>Logout</div>
         </div>
      </> : <>
         <div id="loader" >
            <Link className='btn btnLink' to="/">Leads</Link>
            <Link className='btn btnLink' to="/holder">Holder Leads</Link>
            <Link className='btn btnLink' to="/trackers">Trackers</Link>
            <div className='btn btnLink' onClick={auth.logOut}>Logout</div>
         </div>
      </>}

   </> : <div>
      <Link className='btn btnLink' to="/login">Login</Link>
   </div>
}

export default Header