import React, { useMemo } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useHttp } from "../../../hooks/http.hook";
import Table, { TableCell } from "../../components/Table/Table";
import s from "./s.module.scss"
import { AiFillDelete } from "react-icons/ai";
import { IconContext } from "react-icons";
import _ from 'lodash'
import SimpleModal from '../../components/SimpleModal/SimpleModal'
import config from '../../../config.json'
import useAuth from "../../../hooks/useAuth.hook";
import { Box, Button, Modal, Typography, InputBase, Paper } from '@mui/material'
import ReactCodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import NativeSelect from '@mui/material/NativeSelect';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from "uuid";
import TableWrap from "../../components/Table/TabelWrap";
import tableCss from '../../components/Table/style.module.scss'
import Preloader from "../../components/Preloader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const HolderItem = ({ link, setCheckedLinksObj }) => {
    const [from, setFrom] = React.useState('00:00')
    const [to, setTo] = React.useState('00:00')
    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (name === 'from') {
            setFrom(value)
        }

        if (name === 'to') {
            setTo(value)
        }
    }

    const addHolder = () => {
        setCheckedLinksObj(prev => {
            const newEl = prev.map(el => {
                if (link.id === el.id)

                    if (from > to && to !== '00:00') {
                        el['Holders'].push({
                            id: uuidv4(),
                            holder_from: from,
                            holder_to: '23:59:59'
                        })
                        el['Holders'].push({
                            id: uuidv4(),
                            holder_from: '00:00:00',
                            holder_to: to
                        })
                    } else {
                        el['Holders'].push({
                            id: uuidv4(),
                            holder_from: from,
                            holder_to: to === '00:00' ? '23:59:59' : to
                        })
                    }
                const uniqueShedule = _.uniqWith(el['Holders'], function (arrVal, othVal) {
                    return (arrVal.holder_from === othVal.holder_from && arrVal.holder_to === othVal.holder_to);
                });
                el['Holders'] = uniqueShedule
                return el
            })

            return newEl

        })
    }

    const removeHolder = (linkId, id) => {

        setCheckedLinksObj(prev => {
            const newEl = prev.map(el => {
                if (linkId === el.id) {
                    el['Holders'] = el['Holders'].filter((item, i) => item.id !== id)
                }

                return el
            })

            return newEl

        })
    }

    return <div className={s.transfer_card}>

        <div className={s.header} style={{ justifyContent: "center" }}>
            Link <b>{link.name}</b> Set Apply time
        </div>

        <div className={s.timeWrap}>From <input type={'time'} name='from' value={from} onChange={changeTime} /> to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} /> <div className="btn btnSuccess" onClick={addHolder}>add</div></div>

        <div className={s.shedule}>
            <Stack sx={{ width: '100%' }} spacing={1}>
                {link.Holders && link.Holders.map((el, i) => <Alert severity="warning" className="shedule-alert">
                    From <b>{el.holder_from}</b> to <b>{el.holder_to}</b>
                    <div className='close' onClick={() => {
                        removeHolder(link.id, el.id)
                    }}><CloseIcon /></div>
                </Alert>)}
            </Stack>
        </div>

    </div>
}

const FromToTime = ({ link, partners, setCheckedLinksObj }) => {
    const [from, setFrom] = React.useState('00:00')
    const [to, setTo] = React.useState('00:00')
    const [changePartner, setChangePartner] = React.useState(partners.filter(partner => partner.is_active)[0].endpoint_name)

    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (name === 'from') {
            setFrom(value)
        }

        if (name === 'to') {
            setTo(value)
        }
    }


    const addShedule = () => {
        setCheckedLinksObj(prev => {
            const newEl = prev.map(el => {
                if (link.id === el.id)

                    if (from > to && to !== '00:00') {
                        el['Transfers'].push({
                            id: uuidv4(),
                            transfer_from: from,
                            transfer_road: changePartner,
                            transfer_to: '23:59:59'
                        })
                        el['Transfers'].push({
                            id: uuidv4(),
                            transfer_from: '00:00:00',
                            transfer_road: changePartner,
                            transfer_to: to
                        })
                    } else {
                        el['Transfers'].push({
                            id: uuidv4(),
                            transfer_from: from,
                            transfer_road: changePartner,
                            transfer_to: to === '00:00' ? '23:59:59' : to
                        })
                    }
                const uniqueShedule = _.uniqWith(el['Transfers'], function (arrVal, othVal) {
                    return (arrVal.transfer_from === othVal.transfer_from && arrVal.transfer_to === othVal.transfer_to);
                });
                el['Transfers'] = uniqueShedule
                return el
            })

            return newEl

        })
    }

    const removeShedule = (linkId, id) => {
        console.log("index: ", id)
        console.log("linkId: ", linkId)

        setCheckedLinksObj(prev => {
            const newEl = prev.map(el => {
                if (linkId === el.id) {
                    el['Transfers'] = el['Transfers'].filter((item, i) => item.id !== id)
                }

                return el
            })

            return newEl

        })
    }

    return <div className={s.transfer_card}>

        <div className={s.header}>
            Link <b>{link.name}</b> transfer to → <NativeSelect
                defaultValue={changePartner}
                inputProps={{
                    name: 'age',
                    id: 'uncontrolled-native',
                }}
                onChange={e => setChangePartner(e.target.value)}
            >
                {partners.map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}
            </NativeSelect>
        </div>

        <div className={s.timeWrap}>From <input type={'time'} name='from' value={from} onChange={changeTime} /> to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} /> <div className="btn btnSuccess" onClick={addShedule}>add</div></div>

        <div className={s.shedule}>
            <Stack sx={{ width: '100%' }} spacing={1}>
                {link.Transfers && link.Transfers.map((el, i) => <Alert severity="info" className="shedule-alert">
                    From <b>{el.transfer_from}</b> to <b>{el.transfer_to}</b> → <b>{el.transfer_road}</b>
                    <div className='close' onClick={() => {
                        removeShedule(link.id, el.id)
                    }}><CloseIcon /></div>
                </Alert>)}
            </Stack>
        </div>

    </div>
}

const AddLink = ({ auth, params, getLinks }) => {
    const { request, loading } = useHttp()
    const [users, setUsers] = React.useState([])

    const [formValue, setFormValue] = React.useState({
        name: '',
        country: 'CA',
        userId: auth.user.id || 0,
        offerId: params.id
    })


    const submitHandler = async (e) => {
        e.preventDefault();
        if (formValue.name.length > 5 && !!formValue.offerId) {
            const res = await request(`/api/main/add/link`, 'POST', formValue)
            if (res)
                getLinks()
        }
    }

    const getUsersList = async () => {
        const res = await request(`/api/main/users`)
        setUsers(res.users)
    }

    React.useEffect(() => {
        getUsersList()
    }, [])

    return <form onSubmit={e => submitHandler(e)}>
        <legend >Add New Link</legend>
        <input name="name" type={'text'} placeholder="Landing Name" value={formValue.name} onChange={e => setFormValue({ ...formValue, [e.target.name]: e.target.value })} />
        <input type="text" value={formValue.country} name='country' list="country" onChange={e => setFormValue({ ...formValue, [e.target.name]: e.target.value })} />
        <datalist id="country">
            <option value={'CA'} />
            <option value={'AU'} />
            <option value={'UK'} />
            <option value={'GM'} />
            <option value={'US'} />
            <option value={'PL'} />
        </datalist>
        {!!auth.user?.isAdmin && !!users.length && <select value={formValue.userId} name='userId' onChange={e => setFormValue({ ...formValue, [e.target.name]: e.target.value })}>
            {users.map(user => <option value={user.id}>{user.email}</option>)}
        </select>}
        <button className="btn btnSuccess" type="submit">Add Link</button>
    </form>
}

const AddOffersLink = () => {
    const params = useParams()
    const { request, loading } = useHttp()
    const [removeId, setRemoveId] = React.useState(0)
    const auth = useAuth()
    const [modal, setModal] = React.useState({
        removeModal: false
    })
    const [searchParams, setSearchParams] = useSearchParams();

    const [nameFilter, setNameFilter] = React.useState(searchParams.get('search') || '')
    // get search query


    const [from, setFrom] = React.useState('00:00')
    const [to, setTo] = React.useState('00:00')
    const [changePartner, setChangePartner] = React.useState()

    const [linksList, setLinksList] = React.useState([])

    const [selectId, setSelectId] = React.useState('')
    const [checkedLinks, setCheckedLinks] = React.useState([])
    const [checkedLinksObj, setCheckedLinksObj] = React.useState([])
    const [partners, setPartners] = React.useState([])

    const [open, setOpen] = React.useState(false);
    const [openTransfer, setOpenTransfer] = React.useState(false);
    const [openHolder, setOpenHolder] = React.useState(false);

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }

    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (name === 'from') {
            setFrom(value)
        }

        if (name === 'to') {
            setTo(value)
        }
    }

    const addShedule = () => {
        setCheckedLinksObj(prev => {

            const newEl = prev.map(el => {

                if (from > to && to !== '00:00') {
                    el['Transfers'].push({
                        id: uuidv4(),
                        transfer_from: from,
                        transfer_road: changePartner,
                        transfer_to: '23:59:59'
                    })
                    el['Transfers'].push({
                        id: uuidv4(),
                        transfer_from: '00:00:00',
                        transfer_road: changePartner,
                        transfer_to: to
                    })
                } else {
                    el['Transfers'].push({
                        id: uuidv4(),
                        transfer_from: from,
                        transfer_road: changePartner,
                        transfer_to: to === '00:00' ? '23:59:59' : to
                    })
                }

                const uniqueShedule = _.uniqWith(el['Transfers'], function (arrVal, othVal) {
                    return (arrVal.transfer_from === othVal.transfer_from && arrVal.transfer_to === othVal.transfer_to);
                });
                el['Transfers'] = uniqueShedule
                return el
            })

            return newEl

        })
    }

    const addHolder = () => {
        setCheckedLinksObj(prev => {

            const newEl = prev.map(el => {

                if (from > to && to !== '00:00') {
                    el['Holders'].push({
                        id: uuidv4(),
                        holder_from: from,
                        holder_to: '23:59:59'
                    })
                    el['Holders'].push({
                        id: uuidv4(),
                        holder_from: '00:00:00',
                        holder_to: to
                    })
                } else {
                    el['Holders'].push({
                        id: uuidv4(),
                        holder_from: from,
                        holder_to: to === '00:00' ? '23:59:59' : to
                    })
                }

                const uniqueShedule = _.uniqWith(el['Holders'], function (arrVal, othVal) {
                    return (arrVal.holder_from === othVal.holder_from && arrVal.holder_to === othVal.holder_to);
                });
                el['Holders'] = uniqueShedule
                return el
            })

            return newEl

        })
    }

    const removeAllShedule = () => {
        setCheckedLinksObj(prev => {
            const newEl = prev.map(el => {
                el['Transfers'] = [];
                return el
            })

            return newEl

        })
    }

    const removeAllHolders = () => {
        setCheckedLinksObj(prev => {
            const newEl = prev.map(el => {
                el['Holders'] = [];
                return el
            })

            return newEl

        })
    }

    const saveShedule = async () => {
        try {
            const res = await request('/api/main/set/link/transfer', "POST", { checkedLinksObj })
            getLinks()
            handleCloseTransfer()
            setCheckedLinks([])
        } catch (error) {
            console.log(error.message)
        }
    }

    const saveHolder = async () => {
        try {
            const res = await request('/api/main/set/link/holder', "POST", { checkedLinksObj })
            getLinks()
            handleCloseHolder()
            setCheckedLinks([])
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleOpenTransfer = () => {
        getCheckedLinks()
        setOpenTransfer(true);
    }

    const handleOpenHolder = () => {
        getCheckedLinks()
        setOpenHolder(true);
    }

    const handleCloseTransfer = () => {
        setOpenTransfer(false);
    }

    const handleCloseHolder = () => {
        setOpenHolder(false);
    }

    const handleOpen = (id) => {
        setSelectId(id)
        setOpen(true);
    }

    const handleClose = () => {
        setSelectId('')
        setOpen(false);
    }

    const links = useMemo(() => {
        return linksList
    }, [linksList])

    const checkAll = (e) => {
        let checkedLinksNew = checkedLinks

        if (e.target.checked) {
            links.filter(el => el.name.includes(nameFilter)).forEach(link => checkedLinksNew.push(link.id))

            _.uniq(checkedLinksNew);

            setCheckedLinks([...checkedLinksNew])

        } else {
            setCheckedLinks([])
        }
    }

    const checkedHandler = (e, id) => {
        let checkedLinksNew = checkedLinks
        if (e.target.checked) {
            checkedLinksNew.push(id)

            setCheckedLinks([...checkedLinksNew])
        } else {
            _.remove(checkedLinksNew, function (n) {
                return n == id;
            });

            setCheckedLinks([...checkedLinksNew])
        }
    }


    const removeHandler = async () => {
        if (!!!removeId) setModal({ ...modal, removeModal: false })
        const res = await request(`/api/main/remove/link?id=${removeId}`)

        if (!res) {
            setRemoveId(0);
            setModal({ ...modal, removeModal: false })
            return;
        }
        _.remove(links, link => link.id === res.id);
        setLinksList(links)
        setModal({ ...modal, removeModal: false })
    }

    const getLinks = async (e) => {
        const res = await request(`/api/main/get/link?id=${params.id}`, 'GET', null, {
            ['x-access-token']: auth.token
        })
        setLinksList(res.links.Links)
    }

    const getPartners = async (e) => {
        const res = await request(`/api/main/get-partners`, 'GET', null, {
            ['x-access-token']: auth.token
        })
        setPartners(res.partners)
        setChangePartner(res.partners[0].endpoint_name)
    }

    const getCheckedLinks = async (e) => {
        const res = await request(`/api/main/get/links`, 'POST', { checkedLinks }, {
            ['x-access-token']: auth.token
        })
        setCheckedLinksObj(res)
    }

    const removeShedule = async (linkId, id) => {
        try {
            const res = await request('/api/main/remove/link/transfer', 'POST', {
                linkId,
                id
            })

            getLinks()
        } catch (error) {
            console.log(error.message)
        }
    }

    const removeHolder = async (linkId, id) => {
        try {
            const res = await request('/api/main/remove/link/holder', 'POST', {
                linkId,
                id
            })

            getLinks()
        } catch (error) {
            console.log(error.message)
        }
    }

    React.useEffect(() => {
        getLinks()
    }, [params.id])

    React.useEffect(() => {
        getPartners()
    }, [])

    return <div className={s.wrap}>
        <div className='card'>
            <div>Links {params.id}</div>
            <Link className='btn btnDanger' to={-1}>back</Link>
            <AddLink auth={auth} params={params} getLinks={getLinks} />

            <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                <InputBase
                    defaultValue={searchParams.get('search') || ''}
                    type="search"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By Name"
                    onChange={nameFilterHandler}
                />
            </Paper>

            {!!checkedLinks.length ? <div className={s.linkManager}>
                <div className='btn btnSuccess' onClick={handleOpenTransfer}>Switcher</div>
                <div className='btn btnWarning' onClick={handleOpenHolder}>Holder</div>
            </div>
                : <div className={s.linkManager}>
                    <div className='btn btnSecondary'>Switcher</div>
                    <div className='btn btnSecondary'>Holder</div>
                </div>
            }
            {!!links.length ? <TableWrap>
                <div className={tableCss.table}>
                    <div className={tableCss.header} style={{ "gridTemplateColumns": `repeat(6,minmax(100px, 1fr))` }}>
                        <div>Link</div>
                        <div>Name</div>
                        <div>Country</div>
                        <div>Tracker</div>
                        <div></div>
                        <div><input type={'checkbox'} checked={checkedLinks.length > 0 ? true : false} onChange={checkAll} /></div>

                    </div>
                    <div className={tableCss.content}>
                        {links.filter(el => el.name.includes(nameFilter)).map(link => <div style={{ "gridTemplateColumns": `repeat(6,minmax(100px, 1fr))`, padding: '8px 0', 'rowGap': '8px' }}>
                            <div>{link.linkStr}</div>
                            <div>{link.name}</div>
                            <div>{link.country}</div>
                            <div>
                                <div className='btn btnLink' onClick={e => { handleOpen(link.id) }}>Track</div>
                            </div>
                            <div>
                                <div className='btn' onClick={e => { setRemoveId(link.id); setModal({ ...modal, removeModal: true }) }}><IconContext.Provider value={{ color: 'red', size: '1.5em' }}><AiFillDelete /></IconContext.Provider></div>
                            </div>
                            <div className={s.cell_wrap}>
                                <label className={s.cell_label}>
                                    <input type={'checkbox'} checked={checkedLinks.includes(link.id)} onChange={e => checkedHandler(e, link.id)} />
                                </label>
                            </div>
                            <div className={s.shedule_list}>
                                {link.Transfers && link.Transfers.map((el, i) => <Alert onClose={() => removeShedule(link.id, el.id)} severity="info">
                                    <b>{el.transfer_from}</b>-<b>{el.transfer_to}</b> → <b>{el.transfer_road}</b>
                                </Alert>)}
                            </div>
                            <div className={s.shedule_list}>
                                {link.Holders && link.Holders.map((el, i) => <Alert onClose={() => removeHolder(link.id, el.id)} severity="warning">
                                    <b>{el.holder_from}</b>-<b>{el.holder_to}</b>
                                </Alert>)}
                            </div>
                        </div>)}
                    </div>


                </div>

            </TableWrap> : <Preloader />
            }
        </div>

        {
            modal.removeModal && !!removeId && <SimpleModal modalName='removeModal' closeSetter={setModal}>
                <legend>Remove Link {removeId}</legend>
                <div className='btn btnSuccess' onClick={e => {
                    removeHandler()
                }}>Yes</div>
                <div className='btn btnDanger' onClick={e => {
                    setRemoveId(0);
                    setModal({ ...modal, removeModal: false })
                }}>No</div>
            </SimpleModal>
        }

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Tracker for link: <b>{selectId}</b>
                </Typography>
                <ReactCodeMirror
                    width="max-content"
                    style={{ textAlign: 'left', margin: '0 auto', fontSize: '1.4em' }}
                    value={`<script src="${process.env.REACT_APP_URL}/static/side_script/tracker.js"></script>
<script>
    btrc('${selectId}');
</script>`}
                    extensions={[javascript({ jsx: true })]}
                />
                <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(`<script src="${process.env.REACT_APP_URL}/static/side_script/tracker.js"></script><script>btrc('${selectId}');</script>`) }}>Copy</Button>
            </Box>
        </Modal>

        {/* Holder */}
        <Modal
            keepMounted
            className={s.sheduleModal}
            open={openHolder}
            onClose={handleCloseHolder}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Stack sx={style} spacing={1} >
                {!!partners.length && changePartner && <Paper>
                    <div className={s.timeWrapForAll}>
                        From <input type={'time'} name='from' value={from} onChange={changeTime} />
                        to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} />

                        <div className="btn btnSuccess" onClick={addHolder}>Set All</div>
                        <div className="btn btnDanger" title="Remove All Transfer" onClick={removeAllHolders}><DeleteForeverIcon /></div>

                    </div>
                </Paper>}

                <div className={s.transferModal}>
                    {checkedLinksObj.map(el => <div>
                        <div>
                            <HolderItem link={el} partners={partners} setCheckedLinksObj={setCheckedLinksObj} />
                        </div>
                    </div>)}
                </div>
                <div className={`btn ${loading ? 'btnSecondary' : 'btnSuccess'}`} onClick={loading ? null : saveHolder}>Save</div>

            </Stack>
        </Modal>

        {/* Switcher */}
        <Modal
            keepMounted
            className={s.sheduleModal}
            open={openTransfer}
            onClose={handleCloseTransfer}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Stack sx={style} spacing={1} >
                {!!partners.length && changePartner && <Paper>
                    <div className={s.timeWrapForAll}>
                        From <input type={'time'} name='from' value={from} onChange={changeTime} />
                        to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} />
                        <NativeSelect
                            defaultValue={partners[0].endpoint_name}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                            onChange={e => setChangePartner(e.target.value)}
                        >
                            {partners.map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}

                        </NativeSelect>
                        <div className="btn btnSuccess" onClick={addShedule}>Set All</div>
                        <div className="btn btnDanger" title="Remove All Transfer" onClick={removeAllShedule}><DeleteForeverIcon /></div>

                    </div>
                </Paper>}

                <div className={s.transferModal}>
                    {checkedLinksObj.map(el => <div>
                        <div>
                            <FromToTime link={el} partners={partners} setCheckedLinksObj={setCheckedLinksObj} />
                        </div>
                    </div>)}
                </div>
                <div className={`btn ${loading ? 'btnSecondary' : 'btnSuccess'}`} onClick={loading ? null : saveShedule}>Save</div>

            </Stack>
        </Modal>
    </div >
}

export default AddOffersLink